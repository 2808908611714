import React from 'react';
import { useTranslation } from 'react-i18next';


const MentionsLegales = () => {
  const { t } = useTranslation();

  const listItemStyle = {
    marginBottom: '20px',
    textAlign: 'left',
  };

  const strongStyle = {
    color: '#FF6347',
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#333',
    textAlign: 'left',
  };

  return (
    
<div >

   <span style={{fontWeight: 'bold',fontSize: '2.5em', color: '#FF6347', marginBottom: '4px'}}>{t('Mentions légales.Mentions légales')} </span>

   <div className="bg-light border border-2 border- rounded p-4 mb-4 mt-4 mx-3">

   <p style={{ fontSize: '16px', lineHeight: '1.5', marginBottom: '20px' }}>
   {t('Mentions légales.Bienvenue')}       </p>

       <ol style={{ listStyle: 'none', padding: 0 }}>
      <li style={listItemStyle}>
      <strong style={strongStyle}>{t('Mentions légales.ÉDITEUR DU SITE')} </strong>
          <p style={paragraphStyle}>AGIR ENSEMBLE POUR LE BIEN-ÊTRE DE TOUS
          <br /> 1395 rue Fleury Est, Suite 102.2, Montréal,Québec, H2C 1R7
          <br /> 1-800 361-3303
</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.HÉBERGEMENT DU SITE')} </strong>
        <p style={paragraphStyle}>{t('Mentions légales.HÉBERGEMENT DU SITE Text')}
        <br /> <strong>Netlify</strong></p> 
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.PROPRIÉTÉ INTELLECTUELLE')}</strong>
          <p>{t('Mentions légales.PROPRIÉTÉ INTELLECTUELLE Text')}</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.DONNÉES PERSONNELLES')}</strong>
          <p>{t('Mentions légales.DONNÉES PERSONNELLES Text')}
</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.COOKIES')}</strong>
          <p>{t('Mentions légales.COOKIES Text')}</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.LIENS EXTERNES')}</strong>
          <p>{t('Mentions légales.LIENS EXTERNES Text')}</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Mentions légales.DROIT APPLICABLE ET JURIDICTION')}</strong>
          <p>{t('Mentions légales.DROIT APPLICABLE ET JURIDICTION Text')}</p>
        </li>
      </ol>


    
    </div>


      
    </div>
  );
}

export default MentionsLegales;
