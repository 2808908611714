import React from 'react';
import { useTranslation } from 'react-i18next';


const PolitiqueDeConfidentialite = () => {
  const { t } = useTranslation();

  const listItemStyle = {
    marginBottom: '20px',
    textAlign: 'left',
  };

  const strongStyle = {
    color: '#FF6347',
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#333',
    textAlign: 'left',
  };

  return (
    <div >
    <span style={{fontWeight: 'bold',fontSize: '2.5em', color: '#FF6347', marginBottom: '4px'}}>{t('Politique de confidentialité.Politique de confidentialité')}</span>

   <div className="bg-light border border-2 border- rounded p-4 mb-4 mt-4 mx-3">

   <ol style={{ listStyle: 'none', padding: 0, }}>
      <li style={listItemStyle}>
      <strong style={strongStyle}>{t('Politique de confidentialité.Engagement envers la Confidentialité')}</strong>
          <p style={paragraphStyle}>{t('Politique de confidentialité.Engagement envers la Confidentialité Text')}
</p>
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Politique de confidentialité.Protection des Informations Personnelles')}</strong>
        <p style={paragraphStyle}>{t('Politique de confidentialité.Protection des Informations Personnelles Text')}</p> 
        </li>
        <li style={listItemStyle}>
        <strong style={strongStyle}>{t('Politique de confidentialité.Usage Responsable des Données')}</strong>
          <p>{t('Politique de confidentialité.Usage Responsable des Données Text')}</p>
        </li>
        
      </ol>
    </div>

      
    </div>
  );
}

export default PolitiqueDeConfidentialite;
