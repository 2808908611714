import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import solidarity from './images/solidarity.svg';
import donation_heart from './images/donation_heart.svg';
import teamwork from './images/teamwork.svg';
import './couleurUnique.css';
import { useTranslation } from 'react-i18next';


const QuiSommesNous = () => {
  const { t, } = useTranslation();

  return (
    <div>

<div style={{ width: '100%', backgroundColor: '#FF6347', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <h1 className="container display-1 text-center text-white ">{t('Qui sommes-nous.Qui sommes-nous')}</h1>
</div>
<div >

      <Container>
        <Row className="g-5">

        <Col lg={6} className="wow fadeInUp" data-wow-delay="0.1s" >
        <div  style={{ width: '100%',  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span className="display-6 text-center"style={{ fontWeight: 'bold', }}>{t('Qui sommes-nous.Notre Mission')}</span>
        </div>

          </Col>
         
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.5s ">
            <div className="h-100 ">
              <div className="bg-light rounded p-4 mb-2  ">
                <p className="text-dark mb-4">{t('Qui sommes-nous.NotreMissionText')}</p>
              </div>
             
            </div>
          </Col>

        </Row>
      </Container>

    


      <Container>
        <Row className="g-3">
          <Col lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
          <div className="couleurUnique" style={{borderRadius: '100%', padding: '10px', display: 'inline-block' }}>
    <svg
      viewBox="0 0 640 512"
      fill="currentColor"
      height="12em"
      width="12em"
    >
      <circle cx="50%" cy="50%" r="50%" fill=" #FF6347" />
      <image xlinkHref={donation_heart} width="100%" height="100%" />
    </svg>
  </div>
            <div >
            <span className="display-6 mb-3 mt-3 ">{t('Qui sommes-nous.Notre Identité')}</span>
              <div className="bg-light rounded p-4 mb-2 ">
                <p className="text-dark mb-4">{t('Qui sommes-nous.NotreIdentitéText')}</p>
              </div>
             
            </div>
          </Col>


          <Col lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
          <div className="couleurUnique" style={{borderRadius: '100%', padding: '10px', display: 'inline-block' }}>
    <svg
      viewBox="0 0 640 512"
      fill="currentColor"
      height="12em"
      width="12em"
    >
      <circle cx="50%" cy="50%" r="50%" fill=" #FF6347" />
      <image xlinkHref={solidarity} width="100%" height="100%" />
    </svg>
  </div>
            <div >
            <span className="display-6 mb-3 mt-3 ">{t('Qui sommes-nous.Nos Valeurs')}</span>
              <div className="bg-light rounded p-4 mb-2 ">
                <p className="text-dark mb-4">{t('Qui sommes-nous.NosValeursText')}</p>
              </div>
             
            </div>
          </Col>


          <Col lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
          <div className="couleurUnique" style={{borderRadius: '100%', padding: '10px', display: 'inline-block' }}>
    <svg
      viewBox="0 0 640 512"
      fill="currentColor"
      height="12em"
      width="12em"
    >
      <circle cx="50%" cy="50%" r="50%" fill=" #FF6347" />
      <image xlinkHref={teamwork} width="100%" height="100%" />
    </svg>
  </div>
            <div >
            <span className="display-6 mb-3 mt-3 ">{t('Qui sommes-nous.Rejoignez Notre Cause')}</span>
              <div className="bg-light rounded p-4 mb-2 ">
                <p className="text-dark mb-4">{t('Qui sommes-nous.RejoignezNotreCauseText')}</p>
              </div>
             
            </div>
          </Col>


        </Row>
      </Container>
      </div>

    </div>
  );
};

export default QuiSommesNous;
