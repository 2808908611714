import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'
import Home from './components/Home'
import Contacteznous from './components/Contactez_nous';
import PolitiqueDeConfidentialite from './components/politiqueDeConfidentialite';
import MentionsLegales from './components/mentionLegales';
import QuiSommesNous from './components/quiSommesNous';
import { BrowserRouter as Router, Navigate , Route,Routes,  } from 'react-router-dom';



function App() {
  return (
    <div className="App">
      <Router>

      <div className="Header">
  <Header />
  </div >

  <div className="BodyandFooter">

  <div className="Body">
  <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/contactez-nous" element={<Contacteznous />} />
  <Route path="/politique-de-confidentialite" element={<PolitiqueDeConfidentialite />} />
  <Route path="/mentions-legales" element={<MentionsLegales />} />
  <Route path="/qui-sommes-nous" element={<QuiSommesNous />} />

{/*  pages en anglais */}
<Route path="/en" element={<Home />} />
          <Route path="/en/contact-us" element={<Contacteznous />} />
          <Route path="/en/privacy-policy" element={<PolitiqueDeConfidentialite />} />
          <Route path="/en/legal-notice" element={<MentionsLegales />} />
          <Route path="/en/who-we-are" element={<QuiSommesNous />} />

  <Route path="*" element={<Navigate to="/" />} />
  </Routes>
  </div>

  <div className="Footer">
  <Footer />
  </div >
  
  </div>

  </Router>
    </div>
  );
}

export default App;
