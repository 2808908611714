import React, { useState } from 'react';
import { Container, Nav, NavDropdown, } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/logo.png';
import Hamburger from 'hamburger-react';
import Drawer from '@mui/material/Drawer';
import './boutton.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  
  const changeLanguage = (lang, url) => {
    i18n.changeLanguage(lang); 

    navigate(url);
    };
  const [isOpen, setOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);


  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const buttonStyle = {
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    font: 'inherit',
    color: 'inherit',
    textAlign: 'left',
    textDecoration: 'underline', 
  };

  const styles = {
    navbar: {
      borderBottom: '1px solid #f84512',
      //position: 'fixed',
      top: 0,
      left: 0,
      maxWidth: '100%',
      height: '13%',
      zIndex: 1000,
    },
    containerLeft: {
      paddingLeft: 0,
      maxWidth: '100%',
    },
  };

  return (
    
     
 
 <Navbar style={styles.navbar} expand="lg">

      <Container className="container-left" style={styles.containerLeft}>
      <div className="d-flex justify-content-between align-items-center"  > 
            <Navbar.Brand href="/">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{
              width: '80px',
              height: 'auto',
             // marginRight: '10px',
            }}
          />
        </Navbar.Brand>
        
        <Nav >
        <div class="text-center">
      <Navbar.Toggle
  aria-controls="responsive-navbar-nav"
  className="d-md-none"
  sx={{
    display: 'block', // Affiche le Toggle par défaut
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
    width: '40px',
  }}
  style={{ display: window.innerWidth >= 600 ? 'none' : 'block' }}
>
            <Hamburger toggled={isOpen} toggle={handleToggle} direction="right" size={24} />
          </Navbar.Toggle>
          </div>
          
          <Nav className="me-auto d-none d-md-flex">
            
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
  <NavDropdown
    title={t('header.Nous découvrir')}
    id="nav-dropdown"
    show={showDropdown}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    
  >
<NavDropdown.Item  href={i18n.language === 'en' ? '/en/who-we-are' : '/qui-sommes-nous'}>
              {t('header.Qui sommes-nous')}</NavDropdown.Item>
    </NavDropdown>

  <Nav.Link href={i18n.language === 'fr' ? '/contactez-nous' : '/en/contact-us'}>
        {t('header.Contactez-nous')}
        
      </Nav.Link>
 
      
  <Nav.Link>

    <div>
  <button
    style={buttonStyle}
    onClick={() => changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')}
  >
    {i18n.language === 'fr' ? 'English' : 'Français'}
  </button>
</div>
  
    </Nav.Link>
</div>

          </Nav>
          
        </Nav>
        </div>
        
      
        
       <Drawer
  variant="temporary"
  open={isOpen}
  onClose={() => {
    setOpen(false);
    setShowDropdown(false);
  }}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    display: 'block', // Affiche le Drawer par défaut
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%',  },
  }}
>

<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <a href="/">
    <img
      src={logo}
      alt="Logo"
      className="img-fluid"
      style={{
        width: '80px',
        height: 'auto',
        marginRight: '10px',
      }}
    />
  </a>

  <div>
  <button
    style={buttonStyle}
    onClick={() => changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')}
  >
    {i18n.language === 'fr' ? 'English' : 'Français'}
  </button>
</div>

<div className="text-right" style={{left: '50%',}}>
                  <a href="https://www.zeffy.com/fr-CA/donation-form/9b19f1f7-e55d-47c3-a8d5-f89aa4a95d55" className="lienStyle" target="_blank" rel="noreferrer">
  <button className="boutonStyle" type="button">{t('header.Faire un don')} <FontAwesomeIcon icon={faHeart} /></button>
</a>
</div>

  <Hamburger toggled={isOpen} toggle={handleToggle} direction="right" size={24} />
</div>

            <Nav className="me-auto d-md-none">
              <NavDropdown
              title={t('header.Nous découvrir')}
              id="nav-dropdown"
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
            <NavDropdown.Item  href={i18n.language === 'en' ? '/en/who-we-are' : '/qui-sommes-nous'}>
              {t('header.Qui sommes-nous')}</NavDropdown.Item>
  

              </NavDropdown>
              <Nav.Link href="Contactez-nous">{t('header.Contactez-nous')}</Nav.Link>
              
            </Nav>
          </Drawer>
          <div  >
  <a href="https://www.zeffy.com/fr-CA/donation-form/9b19f1f7-e55d-47c3-a8d5-f89aa4a95d55" className="lienStyle" target="_blank" rel="noreferrer">
    <button className="boutonStyle" type="button">{t('header.Faire un don')} <FontAwesomeIcon icon={faHeart} /></button>
  </a>
</div>

      </Container>
      

    </Navbar>
    
  );
};

export default Header;
