import React from 'react';
import { faLocationDot,faPhone, } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import  './reseaux_sociaux.css';
import { useTranslation } from 'react-i18next';

// Obtenir l'année
const currentYear = () => {
  var annee = new Date().getFullYear();
  return annee;
};







const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div >
    <footer >
      <div  >
        <Container  >
         <Row>

           {/* <Col lg={4} md={4}>
            <span className="text-light mb-4" style={{ fontSize: '1rem', fontWeight: 'bold' }}>Nous suivre</span> 
            <div style={{ display: 'flex',flexDirection: 'column', justifyContent: 'space-around' }}>
      <a className=" mb-2" href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"  >
  <FontAwesomeIcon className="facebook" icon={faFacebook} size="2x" style={{ color: '#3B5998' }} />
</a>
<a href="https://www.instagram.com/agirensemble.pbt/?utm_source=qr&igsh=ZTB3cDBhcjIxcTBr" target="_blank" rel="noopener noreferrer"  >
<FontAwesomeIcon className="instagram" icon={faInstagram} size="2x" style={{ color: '#F46F30' }} />
</a>
</div>
            </Col>*/}

            <Col lg={6} md={6}>
              <span className="text-light mb-4" style={{ fontSize: '1rem', fontWeight: 'bold' }}>{t('footer.Adresse')}</span>
              <p style={{ textDecoration: 'none', color: 'white',  fontSize: '12px' }}><FontAwesomeIcon icon={faLocationDot} className="me-1" />1395 rue Fleury Est, Suite 102.2,
Montréal, Québec, H2C 1R7</p>
              <p style={{ textDecoration: 'none', color: 'white',  fontSize: '12px' }}><FontAwesomeIcon icon={faPhone} className="me-1" />1-800 361-3303</p>
              {/*<p><i className="fa fa-phone-alt me-3"></i></p>
              <p><i className="fa fa-envelope me-3"></i></p>*/}
            </Col>
            
            <Col lg={6} md={6}>
            <span className="text-light mb-4" style={{ fontSize: '1rem', fontWeight: 'bold' }}>{t('footer.Liens Rapides')}</span>
  <ul className="list-unstyled">
  <li><a  href={i18n.language === 'fr' ? '/contactez-nous' : '/en/contact-us'} style={{ textDecoration: 'none', color: 'white',  fontSize: '12px' }}>{t('footer.Contactez-nous')}</a></li>
  <li><a  href={i18n.language === 'fr' ? '/politique-de-confidentialite' : '/en/privacy-policy'} style={{ textDecoration: 'none', color: 'white',  fontSize: '12px'  }}>{t('footer.Politique de confidentialité')}</a></li>
  <li><a  href={i18n.language === 'fr' ? '/mentions-legales' : '/en/legal-notice'} style={{ textDecoration: 'none', color: 'white',  fontSize: '12px'  }}>{t('footer.Mentions légales')}</a></li>

  </ul>
</Col>

           {/*} <Col lg={3} md={6}>
              <h5 className="text-light mb-4">Newsletter</h5>
              <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
              <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
                <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                <Button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</Button>
              </div>
            </Col>*/}
          </Row>
        </Container>
        <div className="container-fluid copyright">
          <div style={{ borderTop: '1px solid #f84512', backgroundColor: 'white', width: '100%', marginLeft: '0', marginRight: '0', position: 'absolute', left: '0', right: '0' }}>
            <small className="text-center mt-5" style={{ textAlign: 'center', color:'black' }}>Copyright &copy; {currentYear()}. {t('footer.Tous droits réservés')}</small>
            <small className="text-center mt-5" style={{ textAlign: 'center', color:'black', }}> Designed by TechSolutrix</small>
          </div>
        </div>
      </div>
    </footer>
    </div>
  );
}

export default Footer;
