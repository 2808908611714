import React, {useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './couleurUnique.css';
import './bouttonEnvoyer.css';
import { useTranslation } from 'react-i18next';


const Contactez_nous = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const form = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    updateButtonColor();
  }, [formData]);

  useEffect(() => {
    //  masquer le popup après 15 secondes
    let timer;
    if (showPopup) {
      timer = setTimeout(() => {
        setShowPopup(false);
      }, 15000); 
    }

    // Nettoie le timer 
    return () => clearTimeout(timer);
  }, [showPopup]);

  const sendEmail = (e) => {
    e.preventDefault();  
    console.log('Données du formulaire:', formData);


    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        console.log('result:', result);
        setShowPopup(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
      }, (error) => {
          console.log(error.text);
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    updateButtonColor();
  };


  const updateButtonColor = () => {
    const nameInput = document.getElementById('name');
    const emailInput = document.getElementById('email');
    const subjectInput = document.getElementById('subject');
    const messageInput = document.getElementById('message');
    const phoneInput = document.getElementById('phone');
    const isValidname = nameInput.checkValidity();
    const isValidemail = emailInput.checkValidity();
    const isValidsubject = subjectInput.checkValidity();
    const isValidmessage = messageInput.checkValidity();
    const isValidphone = phoneInput.checkValidity();

    if (isValidphone && isValidname && isValidemail && isValidsubject && isValidmessage) {
      buttonRef.current.classList.add('boutonRouge');
    } else {
      buttonRef.current.classList.remove('boutonRouge');
    }
  };

  
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="d-inline-block rounded-pill  text-primary py-1 px-3 mb-3 couleurUnique" >{t('Contactez-nous.Contactez-nous')}</div>
           <br />
            <span className="display-6 mb-5">{t('Contactez-nous.Engagement')}</span>

            <form ref={form} onSubmit={sendEmail}>
                <div className="row g-3">
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            <label htmlFor="name">{t('Contactez-nous.Nom complet')}</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
            <label htmlFor="email">{t('Contactez-nous.Courriel')}</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Phone"
              pattern="[0-9]{10}"
              required
            />
            <label htmlFor="phone">{t('Contactez-nous.Téléphone')}  (1234567890)</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
            />
            <label htmlFor="subject">{t('Contactez-nous.Sujet')}</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating">
            <textarea
              className="form-control"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Leave a message here"
              style={{ height: '100px' }}
              required
            ></textarea>
            <label htmlFor="message">{t('Contactez-nous.Message')}</label>
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="bouttonEnvoyer" ref={buttonRef}> 
          {t('Contactez-nous.Envoyer')}
          </button>
        </div>
      </div>
      
      <style>
        {`
          .boutonRouge {
            background-color: #ff6347;
            color: #3498db;
            border: 1px solid #3498db;
          }
        `}
      </style>
    </form>

          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '450px' }}>
            <div className="position-relative rounded overflow-hidden h-100">
            <iframe
  title="Google Maps"
  className="position-relative w-100 h-100"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11174.478443377917!2d-73.66717496723507!3d45.5579771469402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc918b6c585575d%3A0x31eb8c7261691c34!2s1395%20Rue%20Fleury%20E%2C%20Montr%C3%A9al%2C%20QC%20H2C%201R7!5e0!3m2!1sfr!2sca!4v1705963484271!5m2!1sfr!2sca" 
  
  style={{ minHeight: '450px', border: '0' }}
  allowFullScreen=""
  aria-hidden="false"
  tabIndex="0"
></iframe>

            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <p
          style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            border: '1px solid red',
            background: 'green',
            padding: '10px',
            color: '#fff',
          }}
        >
        {t('Contactez-nous.Messageremerciement')}
        </p>
      )}
    </div>
  );
};

export default Contactez_nous;
