import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col, Image, } from 'react-bootstrap';
import Donation from './images/Donation.jpg';
import Aideaine from './images/Aideaine.png';
import './boutton.css';
import './couleurUnique.css';
import { useTranslation } from 'react-i18next';


function Home() {
  const [index, setIndex] = useState(0);
  const { t, i18n } = useTranslation();


  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  
  return (
    <>    

     {/*<!-- Carousel -->*/}
     <div >
     <Carousel activeIndex={index} onSelect={handleSelect} style={{ maxWidth: '100%',marginBottom: '40px', }}>
     <Carousel.Item key="donation-carousel-item">         
     <img
          className="w-100 h-100"
          src={Donation}
          alt="Donation"
          style={{
            objectFit: 'cover',
            height: '100%',
            width: '100%',
          }}
        />
        <Carousel.Caption>
         {/*} <h3>Étiquette de la première diapositive</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
        </Carousel.Caption>
      </Carousel.Item>

      
      {/*
      <Carousel.Item>
        <img
          className="img-fluid"
          src={Donation}
          alt="Logo"
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100vh',
          }}
        />
        <Carousel.Caption>
          <h3>Étiquette de la deuxième diapositive</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="img-fluid"
          src={logo}
          alt="Logo"
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100vh',
          }}
        />
        <Carousel.Caption>
          <h3>Étiquette de la troisième diapositive</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption>
      </Carousel.Item>
        */}
    </Carousel>
 {/*<!-- Carousel -->*/}
 
 



    {/*<!-- À propos de nous début -->*/}
    <div className="container-xxl py-5">
      <Container>
        <Row className="g-5">
         
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <div className="d-inline-block rounded-pill  text-white py-1 px-3 mb-3 couleurUnique">{t('home.A propos-nous')}</div>
              <br />
              <div className="bg-light border-bottom border-top border-left border-5 border-danger rounded p-4 mb-2 mt-2">
                <p className="text-dark mb-4"> {t('home.A propos-nousText')}</p>
              </div>
             
              <button className="boutonStyle" type="button" 
        onClick={() => window.location.href = i18n.language === 'fr' ? '/contactez-nous' : '/en/contact-us'}>        
        {t('home.Contactez-nous')}
</button>
            </div>
            
          </Col>

          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.1s" >
          <div className="position-relative overflow-hidden" style={{ minHeight: '400px',  }}>
  <Image src={Aideaine} alt="Aideaîne" className="position-absolute top-0 start-0 w-100 h-100 img-fluid" style={{ objectFit: 'cover' }} />
</div>

          </Col>
        </Row>
      </Container>
    </div>
    </div>
    {/*<!-- À propos de nous fin -->*/}


    
</>
  );
}

export default Home;